export enum AppUserRoles {
  SystemAdmin = "User.SystemAdmin",
  Admin = "User.Admin",
  Editor = "User.Editor",
  SAMHSA = "User.SAMHSA",
  Reader = "User.Reader",
}

export enum ApprovalWorkflowStatus {
  SubmittedToCore = "SubmittedToCore",
  ApprovedAndSubmittedToFiscal = "ApprovedAndSubmittedToFiscal",
  ApprovedAndSubmittedToSAMHSA = "ApprovedAndSubmittedToSAMHSA",
  Approved = "Approved",
  NotApproved = "NotApproved",
  Completed = "Completed",
}

export enum ApprovalWorkflowStep {
  None = "None",
  SubmitToCore = "SubmitToCore",
  ApproveAndSubmitToFiscal = "ApproveAndSubmitToFiscal",
  ApproveAndSubmitToSAMHSA = "ApproveAndSubmitToSAMHSA",
  Approve = "Approve",
  NotApprove = "NotApprove",
  Complete = "Complete",
}

export enum RecordStatus {
  Draft = "Draft",
  Submitted = "Submitted",
  NotApproved = "NotApproved",
  Approved = "Approved",
  InProgress = "InProgress",
  SubmittedToCore = "SubmittedToCore",
  SubmittedToFiscal = "SubmittedToFiscal",
  SubmittedToSAMHSA = "SubmittedToSAMHSA",
  Completed = "Completed",
  EvaluationPending = "EvaluationPending",
  EvaluationSent = "EvaluationSent",
}

export enum GranteeSelectionOptionType {
  AllRegions = "All_Regions",
  AllGranteeTypes = "All_GranteeTypes",
  AllGrantees = "All_Grantees",
  AllGrantPrograms = "All_GrantPrograms",
}

export enum LookupType {
  Regions = "Regions",
  GranteeTypes = "GranteeTypes",
  Grantees = "Grantees",
  GrantPrograms = "GrantPrograms",
  ActivityTypes = "ActivityTypes",
  TAMethods_Virtual = "TAMethods_Virtual",
  TAMethods_FaceToFace = "TAMethods_FaceToFace",
}

export enum LookupCode {
  ActivityConsultation = "Activity_Consultation",
  ActivityEvent = "Activity_Event",
}

export enum FocusArea {
  Assessment = "FA_1",
  Capacity = "FA_2",
  CulturalCompetence = "FA_3",
  Evaluation = "FA_4",
  Implementation = "FA_5",
  Planning = "FA_6",
  Sustainability = "FA_7",
}

export enum ActivityType {
  Base = "Activity",
  Consultation = "Consultation",
  Event = "Event",
}

export enum HSStatFormat {
  HSInteger,
  HSDecimal,
  HSFormattedInteger,
  HSCurrency,
  HSDateTime,
  HSTime,
}

export enum GranteeSelectionFilterType {
  Invited,
  Attended,
}





export enum HSLocalStorage {
  HSUSERACTIVITY = "HSUSERACTIVITY"
}





export enum HourlyExpensesTypes {

  Delivery = "SEH_Delivery"
}