import React, { useContext } from "react";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { LookupsContext } from "contexts/lookupsContext";

interface FocusAreaViewProps {
  data: BaseRecord | undefined;
}

export const FocusAreaView: React.FC<FocusAreaViewProps> = ({ data }) => {
  const { getLookupDescriptions } = useContext(LookupsContext);
  return (
    <>
      <h3 className="h5 mt-3 mb-3 py-2 text-bg-light ">Strategic Prevention Framework Focus Areas</h3>

      <div className="row">
        <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which elements of the SPF does this activity represent?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas && data?.focusArea?.lookup_FocusAreas.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas).map((item: string, index: number) => (
                  <React.Fragment key={index}>
                    <span>{item}</span>
                    {index < data?.focusArea.lookup_FocusAreas.length - 1 ? <span> | </span> : ""}
                  </React.Fragment>
                                  ))
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which element(s) of assessment is represented by this request?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas_Assessment && data?.focusArea?.lookup_FocusAreas_Assessment.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas_Assessment).map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <span>{item.toLowerCase().includes("other") ? "Other" + (data?.focusArea.assessment_Other ? " (" + data?.focusArea.assessment_Other + ")" : "") : item}</span>
                    {index < data?.focusArea.lookup_FocusAreas_Assessment.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                ))
              : "N/A"}
          </p>
        </div>

        <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which element(s) of implementation is represented by this request?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas_Implementation && data?.focusArea?.lookup_FocusAreas_Implementation.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas_Implementation).map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <span>{item.toLowerCase().includes("other") ? "Other" + (data?.focusArea.implementation_Other ? " (" + data?.focusArea.implementation_Other + ")" : "") : item}</span>
                    {index < data?.focusArea.lookup_FocusAreas_Implementation.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                ))
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="row">
      <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which element(s) of capacity is represented by this request?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas_Capacity && data?.focusArea?.lookup_FocusAreas_Capacity.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas_Capacity).map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <span>{item.toLowerCase().includes("other") ? "Other" + (data?.focusArea.capacity_Other ? " (" + data?.focusArea.capacity_Other + ")" : "") : item}</span>
                    {index < data?.focusArea.lookup_FocusAreas_Capacity.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                ))
              : "N/A"}
          </p>
        </div>
        <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which element(s) of planning is represented by this request?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas_Planning && data?.focusArea?.lookup_FocusAreas_Planning.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas_Planning).map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <span>{item.toLowerCase().includes("other") ? "Other" + (data?.focusArea.planning_Other ? " (" + data?.focusArea.planning_Other + ")" : "") : item}</span>
                    {index < data?.focusArea.lookup_FocusAreas_Planning.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                ))
              : "N/A"}
          </p>
        </div>

        
      </div>

      <div className="row">
        <div className="col-lg-6 ">
          <span className="fw-bold d-flex mb-2">Which element(s) of evaluation is represented by this request?</span>
          <p>
            {data?.focusArea?.lookup_FocusAreas_Evaluation && data?.focusArea?.lookup_FocusAreas_Evaluation.length > 0
              ? getLookupDescriptions(data?.focusArea.lookup_FocusAreas_Evaluation).map((item: string, index: number) => (
                <React.Fragment key={index}>
                    <span>{item.toLowerCase().includes("other") ? "Other" + (data?.focusArea.evaluation_Other ? " (" + data?.focusArea.evaluation_Other + ")" : "") : item}</span>
                    {index < data?.focusArea.lookup_FocusAreas_Evaluation.length - 1 ? <span> | </span> : ""}
                    </React.Fragment>
                ))
              : "N/A"}
          </p>
        </div>
      </div>
    </>
  );
};
