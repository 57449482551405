import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSColumnChart } from "components/charts/HSColumnChart";


type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};
export const ActivityRegionChart: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [chartData, setChartData] = useState<{ name: string; y: number }[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const {   getLookupDescription } = useContext(LookupsContext);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    const distinctCounts = countDistinctRegions(dta);

    let tmpChartData: { name: string; y: number }[] = [];
    if (distinctCounts != null) {
      if (distinctCounts != null) {
        for (var i = 0; i < distinctCounts.length; i++) {
          tmpChartData.push({
            name: distinctCounts[i].name === 'Other (Please specify)' ? 'Other' : distinctCounts[i].name,
            y: distinctCounts[i].count,
          });
        }
      }


      

      if (tmpChartData != null)
    {




      tmpChartData.sort((y, z) => y.name.localeCompare(z.name));
    }
    
      setChartData(tmpChartData);
    }
  }, [dta]);

  // const getDashboardChart = async (reportCode: string) => {
  //   const response = await axiosInstance.get(`${API_URL}/reports/chart`, {
  //     params: {
  //       reportCode: reportCode,
  //     },
  //   });

  function countDistinctRegions(activities: BaseRecord[]): { name: string; count: number }[] {
    //console.log("countDistinctStatuses: data", objects);
    const regionCounts: { [name: string]: number } = {};

    // Count the occurrences of each status
    for (const att of activities) {






      let isInRegion: boolean = false;

      let activityRegions: string[] = [];

      if (att.granteeAttendancesConsultation != null) {
        for (const sel of att.granteeAttendancesConsultation) {
          if (activityRegions.includes(sel.regionCode) === false) {
            activityRegions.push(sel.regionCode);
          }
        }
      }
      if (att.granteeAttendancesEvent != null) {
        for (const sel of att.granteeAttendancesEvent) {
          if (activityRegions.includes(sel.regionCode) === false) {
            activityRegions.push(sel.regionCode);
          }
        }
      }


      
      
      if (activityRegions != null) {
for (const sel of activityRegions) {
        if (regionCounts[sel] != null) {
          regionCounts[sel]++;
        } else {
          regionCounts[sel] = 1;
        }
      }
      }
      
      

    }

    // Convert the counts to an array of { name, count } objects
    const distinctCounts: { name: string; count: number }[] = [];
    for (const region in regionCounts) {
      let regionCode: string = filterForm?._formValues?.granteeSelections[0]?.regionCode;

      
            if (regionCode != null && regionCode != "") {
        if (regionCode === "All_Regions" || region === regionCode) {
          distinctCounts.push({ name: getLookupDescription(region), count: regionCounts[region] });
        }
      } else {
        distinctCounts.push({ name: getLookupDescription(region), count: regionCounts[region] });
      }
    }

        
    
    
    
    
    
    return distinctCounts;
    
  }

  
  
  
  
  
  
  
  
  return <HSColumnChart reportCode="ACTIVITYREGIONCODE" chartData={chartData} isLoading={isLoading} />;
};
